// common
import NotFound from './components/NotFound';
import Layout from './components/Layout';
import IconsLayout from './components/IconsLayout';
import {default as URLAdd} from './components/URL/Add';
import {default as URLEdit} from './components/URL/Edit';
import {default as URLIndex} from './components/URL/Index';
import {default as URLRemove} from './components/URL/Remove';
import {default as URLQR} from './components/URL/QR';

// auth
import Logout from './components/Auth/Logout';
import Signup from './components/Auth/Signup/Signup';
import Signin from './components/Auth/Signin/Signin';
import Activate from './components/Auth/Activate/Activate';
import PasswordForgot from './components/Auth/PasswordForgot/PasswordForgot';
import PasswordForgotSent from './components/Auth/PasswordForgot/Sent';
import PasswordForgotChange from './components/Auth/PasswordForgot/Change';
import PasswordForgotModified from './components/Auth/PasswordForgot/Modified';


import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";

const router = createBrowserRouter([
    {   
        path: "not-found",
        element: <NotFound />,
    },
    {   
        path: "user/urls/",
        element: <IconsLayout />,
        children: [
            {   
                path: "",
                element: <URLIndex />,
            },
            {   
                path: "qr",
                element: <URLQR />,
            },
            {   
                path: "edit",
                element: <URLEdit />,
            },
            {   
                path: "add",
                element: <URLAdd />,
            },
            {   
                path: "remove",
                element: <URLRemove />,
            },
        ]
    },
    {
        path: "",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Navigate to="/auth/sign-in/" replace />
                },
        ]
    },

    {   
        path: "auth",
        element: <Layout />,
        children: [
            {
                path: "activate/email/:uid",
                element: <Activate />
                },
            {
                path: "password-forgot/change/:uid",
                element: <PasswordForgotChange />
                },
            {
                path: "password-forgot/modified",
                element: <PasswordForgotModified />
                },
            {
                path: "password-forgot/",
                element: <PasswordForgot />
                },
            {
                path: "password-forgot/sent",
                element: <PasswordForgotSent />
                },
            {
                path: "sign-in",
                element: <Signin />
                },
            {
                path: "logout",
                element: <Logout />
                },
            {
                path: "sign-up",
                element: <Signup />
                },
        ],
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

const App = () => {
    return (
        <RouterProvider router={router} />
        );
}

export default App;
