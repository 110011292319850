import {useState} from 'react';
import {IconButton} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const Share = (props) => {
    const [copied, setCopied] = useState(false);
    const data = props.data;

    const handleClick = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
        navigator.clipboard.writeText(data);
    }

    return (<><IconButton size="large" onClick={() => handleClick()}>{!copied ? (<ShareIcon />) : (<AssignmentTurnedInIcon color="success" />)}</IconButton></>);
}

export default Share;
