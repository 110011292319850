import {useState, useEffect} from 'react';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {patch, post} from './api';
import Message from './Message';

const Edit = () => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [target, setTarget] = useState("");
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                setTarget(data.target);
            });
        }
    }, [loaded, uid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        patch(uid, event).then(data => {
            if(data.status !== 'error'){
                navigate('..', {state: {message: data, uid: uid}});
            } else {
                navigate('.', {state: {message: data, uid: uid}});
            }
        });
    }

    return (<Container maxWidth="sm">
        <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                <TextField
                name="target"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
                required
                fullWidth
                id="target"
                label="URL objetivo (ej. https://example.com)"
                autoFocus />

                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('..', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>

        </Container>);
}

export default Edit;
