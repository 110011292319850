import {Avatar,Typography,Container,Paper} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const PasswordForgotModified = () => {
    return (<Container maxWidth="xs" align="center" sx={{mt:4}}>
        <Paper elevation={2} sx={{ p:2, pb:4}}>
            <Avatar sx={{ m: 1, mt:2, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h6" sx={{ mb:2 }}>
                Contraseña Actualizada
            </Typography>
            <CheckBoxIcon sx={{ width: 128, height: 128 }} color="success" />
        </Paper>
        </Container>);
}

export default PasswordForgotModified;
