import {Grid, Paper, Box, Stack, Divider, List, ListItem, ListItemText, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useNavigate} from 'react-router-dom';
import Share from './Share';

const IndexItem = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    const ImgComponent = (qrData) => (<img src={qrData} width="100" height="100" alt="QR" />);
    
    return (
        <Grid item xs={12} lg={4}>
            <Paper>
                <Stack direction="row">
                    <List sx={{width:'65%'}}>
                        <ListItem>
                            <ListItemText primary="URL objetivo" secondary={item.target} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="URL acortada" secondary={item.shorturl} />
                        </ListItem>
                    </List>
                    <List sx={{width:'35%'}}>
                        <ListItem>
                            <ListItemText primary="QR" secondary={ImgComponent(item.qr)} />
                        </ListItem>
                    </List>
                </Stack>
                <Box>
                    <Divider />
                    <Stack direction="row" spacing={2} display="flex" justifyContent="space-between" sx={{p:2}}>
                        <IconButton onClick={() => navigate('remove', {state: {uid: item.uid}})} aria-label="Borrar" size="large">
                            <DeleteIcon />
                        </IconButton>

                        <Share data={item.shorturl}/>
                        <IconButton onClick={() => navigate('qr', {state: {uid: item.uid}})} aria-label="Editar" size="large">
                            <QrCodeIcon />
                        </IconButton>
                        <IconButton onClick={() => navigate('edit', {state: {uid: item.uid}})} aria-label="Editar" size="large">
                            <EditIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </Paper>
            </Grid>);
}
export default IndexItem;
