import {Typography,Container,Paper} from '@mui/material';
import Message from '../Message';
import SendIcon from '@mui/icons-material/Send';

const PasswordForgotSent = () => {
    return (<Container maxWidth="xs" align="center" sx={{mt:4}}>
        <Paper elevation={2} sx={{ p:2}}>
            <Message />
            <Typography variant="h6" sx={{ mb:2 }}>
                Correo de Recuperación Enviado
            </Typography>
            <SendIcon sx={{width:128, height:128}} color="info" />
        </Paper>
        </Container>);
}

export default PasswordForgotSent;
