import {Typography,Container,Paper} from '@mui/material';
import Message from '../Message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const PasswordForgotExpired = () => {
    return (<Container maxWidth="xs" align="center" sx={{mt:4}}>
        <Paper elevation={2} sx={{ p:2}}>
            <ReportProblemIcon sx={{ fontSize: "100px" }} color="error" />
            <Typography variant="h6" sx={{ mb:2 }}>
                Enlace expirado
            </Typography>
            <Message />
        </Paper>
        </Container>);
}

export default PasswordForgotExpired;
