import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {retrieveActivation} from '../api/activate.js';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import {Container, Stack, Box, Paper} from '@mui/material';
import Loader from '../Loader';

const Activate = () => {
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] = useState(undefined);
    const {uid} = useParams();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveActivation(uid).then((data) => {
                setResponse(data);
            });
        }
    }, [loaded, uid]);
    return (<>
        {!loaded && (
            <Loader />
            )}
        {response !== undefined && (<Container maxWidth="xs">
            <Paper elevation={2} sx={{ pt: 2, pb:2, mt:2 }}>
                <Stack direction="row" sx={{p:2}}>
                    <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minWidth: "50%" }}
                >
                        {response.status === "success" ? (
                    <AssignmentTurnedInIcon sx={{ fontSize: "100px" }} color="success" />
                    ) : (
                    <AssignmentLateIcon sx={{ fontSize: "100px" }} color="error" />
                    )}
                </Box>
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                    <Stack align="center">
                        {response.message}
                    </Stack>
                </Box>
            </Stack>
        </Paper>
        </Container>)}
    </>);
}

export default Activate;
