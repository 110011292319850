import {useState, useEffect} from 'react';
import {Container, Grid, Paper, Typography} from '@mui/material';
import {list} from './api';
import Message from './Message';
import Loader from './Loader';
import IndexItem from './IndexItem';
import FabAdd from './FabAdd';
import FabAddItem from './FabAddItem';
import CustomPagination from './Pagination';

const Index = () => {
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [items, setItems] = useState(undefined);
    const [page, setPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [pagination, setPagination] = useState(undefined);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoader(true);
            list().then(data => {
                setItems(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
                setLoader(false);
            })
        }
    }, [loaded, items]);

    useEffect(() => {
        if(!loader && prevPage !== page) {
            setLoader(true);
            setItems(undefined);
            setPrevPage(page);
            list(page).then(data => {
                setItems(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
                setLoader(false);
            });
        }
    }, [page, prevPage, loader]);


    return (<Container maxWidth="lg">
        <Message />
        <Loader loader={loader} />
        <FabAdd target="add" />
        {items !== undefined && Array.isArray(items) && items.length > 0 && (<><Grid container spacing={2}>
            {items.map((row, index) => <IndexItem key={"item-"+index} item={row} />)}
            <FabAddItem target="add" />
        </Grid>
        {pagination !== undefined && (<CustomPagination setItems={setItems} pages={pagination.pages} setPage={setPage} page={page} />)}
        </>)}
{items !== undefined && Object.keys(items).length === 0 && (<Paper sx={{p:8}}>
    <Typography align="center"variant="h6">No hay items para mostrar</Typography>
    </Paper>)}
    </Container>);
}

export default Index;
