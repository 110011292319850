import {Box, Pagination} from '@mui/material';

const CustomPagination = (props) => {
    const pages = props.pages;
    const setPage = props.setPage;
    const page = props.page;

    return (<>
        {pages > 1 && (<Box display="flex" justifyContent="center" alignItems="center" sx={{pt:2}}>
            <Pagination size="large" count={pages} onChange={(event, _page) => { setPage(_page); }} variant="outlined" shape="rounded" page={page} />
            </Box>)}
    </>);
}

export default CustomPagination;
