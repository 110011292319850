import {useState, useEffect} from 'react';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {put} from './api';
import Message from './Message';

const Add = () => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    const handleSubmit = (event) => {
        event.preventDefault();
        put(event).then(data => {
            if(data.status === 'success') {
                navigate('..', {state: {message: data}});
            } else {
                navigate('.', {state: {message: data}});
            }
        });
    }

    return (<Container maxWidth="sm">
    <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                <TextField
                name="target"
                required
                fullWidth
                id="target"
                label="URL objetivo (ej. https://example.com)"
                autoFocus />

                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('..', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>

        </Container>);
}

export default Add;
