const list_endpoint = 'https://api.24x7.cl/private/urls/';
const endpoint = 'https://api.24x7.cl/private/url/';

export const remove = (uid) => {
    const options = {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const post = (uid) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const patch = (uid, event) => {
	const formData = new FormData(event.currentTarget);
    formData.set('uid', uid);
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(Object.fromEntries(formData)),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const put = (event) => {
	const formData = new FormData(event.currentTarget);
    const options = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(Object.fromEntries(formData)),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const list = (page=null) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    if(page!==null)
        return fetch(list_endpoint+'?page='+page, options).then((response) => response.json());
    return fetch(list_endpoint, options).then((response) => response.json());
}
