import {useState, useEffect} from 'react';
import {config} from '../constants/config';
import {jwtValidate, jwtRefresh} from '../api/jwt';

export const useAuthenticated = () => {
    const [loaded, setLoaded] = useState(false);
    const [valid, setValid] = useState(true);
    const [lock, setLock] = useState(false);

    useEffect(() => {
        const refresh = () => {
            if(!lock) { 
                setLock(true);
                console.log('refresh');
                jwtRefresh().then((data) => {
                    if(data.hasOwnProperty('status') && data.status === 'success') {
                        setValid(true);
                    } else {
                        setValid(false);
                    }
                    setLock(false);
                }).catch((error) => {
                    setValid(false);
                    setLock(false);
                })
            }
        }
        const validate = () => {
            if (!lock) { 
                console.log('validate');
                jwtValidate().then((data) => {
                    if(data.hasOwnProperty('status') && data.status === 'success') {
                        setValid(true);
                    } else {
                        setValid(false);
                    }
                }).catch((error) => {
                    setValid(false);
                });
            }
        }
        if(!loaded) {
            console.log('useAuthenticated loaded.');
            setLoaded(true);
            validate();
            return;
        }
        const refreshIDx = setInterval(() => refresh(refreshIDx), config.useauthenticated_refresh_interval);
        const validateIDx = setInterval(() => validate(validateIDx), config.useauthenticated_validate_interval);
        return () => { if(!isNaN(refreshIDx)) { clearInterval(refreshIDx); }; if(!isNaN(validateIDx)) {clearInterval(validateIDx); }; return valid; }
    }, [loaded, valid, lock]);

    return valid;
}
