import {useEffect, useState} from 'react';
import {sendLogout} from './api/auth';
import {Container} from '@mui/material';
import {useNavigate} from 'react-router-dom';
const Logout = () => {
    const navigate = useNavigate();
    const [tryLogout, setTryLogout] = useState(false);
    useEffect(() => {
        if(!tryLogout) {
            setTryLogout(true);
            sendLogout().then(data => {
                if(data.hasOwnProperty('status') && data.status === 'success') {
                    navigate('/');
                }

            })
        }
    }, [tryLogout, navigate]);
    return (<Container maxWidth="xs" align="center" sx={{mt:2}}>
        <>Logout</>
        </Container>);
}

export default Logout;
