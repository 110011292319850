import { useEffect, useState, forwardRef } from 'react';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import { useNavigate, NavLink} from 'react-router-dom';

// icons
import LanguageIcon from '@mui/icons-material/Language';
//import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';

import './IconsLayoutMenu.css';

const CustomNavLink = forwardRef((props, ref) => (<NavLink
ref={ref}
to={props.to}
className={({ isActive }) => `${props.className} ${isActive ? props.activeClassName : props.inactiveClassName}`}>
{props.children}
</NavLink>));

const DrawerMenu = (props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (
        <>
        <List sx={{p:0}}>
            <ListItem component={CustomNavLink} activeClassName='active-link' inactiveClassName="inactive-link" to="/user/urls/" key="menu-urls" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/user/urls/')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/user/urls/')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Short URLs" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List sx={{p:0}}>
            <ListItem component={CustomNavLink} activeClassName='active-link' inactiveClassName="inactive-link" to="/auth/logout" key="menu-logout" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/auth/logout')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/auth/logout')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </List>
        </>);
}
export default DrawerMenu;
