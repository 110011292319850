import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Container, Box} from '@mui/material';

const Loader = () => {
  return (
  <Container maxWidth="xs">
    <Box sx={{ width: "100%", display: 'flex', mt: 20, justifyContent: "center" }}>
      <CircularProgress />
    </Box>
    </Container>
  );
}

export default Loader;
