import {Fab} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const FabAdd = (props) => {
    const target = props.target;
    const navigate = useNavigate();
    return (<Fab variant="contained" sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }} color="primary" onClick={() => navigate(target)} aria-label="Añadir"><AddIcon /></Fab>);
    }
export default FabAdd;
